import axios from "../../utils/axios";

//
export function adList(params) {
    return axios({
        url: "/admin/ad/list",
        method: "get",
        params: params
    });
}

//
export function adSave(data, formName, method = "post") {
    let url =
        formName === "add"
            ? "/admin/ad/add"
            : "/admin/ad/update";
    return axios({
        url: url,
        method: method,
        data: data
    });
}

//
export function adDelete(data) {
    return axios({
        url: "/admin/ad/delete",
        method: "post",
        data: data
    });
}
